@import 'variables.scss';

.review-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    .review{
        flex-basis: 0;
        flex-grow: 0;
        margin: 1rem;
        padding: 1rem;
        color: $primary;
        min-width: 350px;

        h1 {
            white-space: nowrap;
        }

        .review-img {
            margin-bottom: 1rem;
            max-width: 400px;
            width: 100%;
            box-shadow: $drop-shadow;
        }

        table {
            width: 100%;
            table-layout: auto;
            font-size: 20px;
            border-collapse: collapse;
            
            thead {
                margin-top: 1rem;
            }

            th, td{
                text-align: start;
                font-weight: normal;
            }

            th {
                border-bottom: 1px solid $primary;
            }

            tbody {
                tr {
                    line-height: 1.5;
                    transition: background-color .2s linear;

                    &:nth-child(odd){
                        background-color: $black;
                    }

                    &:hover{
                        background-color: $gray;
                    }
                }
            }
        }

    }
    
    a {
        color: $link;
    }

    .panel {
        &-label {
            position: relative;
            display: block;
            width: 100%;
            color: $primary;
            background: none;
            border: none;
            text-align: left;
            padding: 0;
            font-size: 1rem;
            font-weight: bold;
            font-family: inherit;
            transition: color .2s linear;
            cursor: pointer;

            &:hover {
                color: white;
            }

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 12px;
                width: 22px;
                height: 2px;
                margin-top: -2px;
                background-color: $primary;
            }

            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 12px;
                width: 22px;
                height: 2px;
                margin-top: -2px;
                background-color: $primary;
                transform: rotate(-90deg);
                transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            }

            h2 {
                margin: 0;
            }

            hr {
                margin: 0;
                box-shadow: none;
                border: 2px solid $primary;
                height: auto;
            }
        }
    }
    
    .panel[aria-expanded='true'] {
        .panel-content {
            opacity: 1;
        }

        .panel-label {
           color: $primary;
            &:before {
                transform: rotate(0deg);
            }
        }
    }

    .panel-inner {
        overflow: hidden;
        will-change: height;
        transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    .panel-content {
        opacity: 0;
        padding: 1rem;
        background-color: $secondary-black;
        transition: opacity 0.3s linear 0.18s;
    }
}