@import "variables.scss";
@import "components/reviews/reviews.scss";
@import "components/about/about.scss";
@import "components/merch/merch.scss";
@import "components/footer/footer.scss";

* {
  &:focus {
    outline: 2px solid $link;
  }
}

hr {
  height: 12px;
  border: 0;
  box-shadow: $drop-shadow;
}

.App {
  text-align: center;
  background-color: $black;
  color: $primary;
}

.app-header {
  background-color: $gray;
  background-image: url('/imgs/backgrounds/hero_resized.png');
  background-size: cover;
  background-position: top;
  color: $white;
  height: 100vh;
  box-shadow: $drop-shadow;

  .app-logo {
    margin-top: 8rem; // To compensate for the fixed merch-bar 58px
    max-width: 768px;
    width: 90vw;
  }
}

.review-wrapper {
  background-color: $black;
}

.app-footer {
  color: $white;
  min-height: 2rem;
  background-color: $secondary-black;
}

a {
  color: $link;
  transition: color .2s linear;

  &:hover {
    color: white;
  }
}