@import 'variables.scss';

.about {
    margin-bottom: 4rem;
    color: $primary;

    .container {
        max-width: 900px;
        margin: auto;
        padding: 0 1rem;
    }

    .portrait-wrapper {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        figure {
            margin: 1rem;
            max-width: 20rem;
            box-shadow: $drop-shadow;
            border-radius: 4px;
            border: 2px solid black;
            background-color: $secondary-black;

            figcaption {
                padding: 0.5rem 0;
                font-size: 2rem;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .about {
        .container {
            padding: unset;
        }
    }
}