@import 'variables.scss';

.footer {
    .footer-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        color: $primary;
        
        .social-wrapper {
            font-size: 2rem;

            a {
                margin-right: 1rem;
            }
        }
        
        .contact-wrapper {
            font-size: 2rem;
        }

        h3 {
            margin-bottom: 0.5rem;
        }
    }

    p {
        margin-bottom: 0;
        padding-bottom: 1rem;
    }
}

@media only screen and (min-width: 768px) {
    .social-wrapper, .contact-wrapper {
        margin: 1rem 4rem;
    }
}