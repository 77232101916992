@import 'variables.scss';

.merch {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 8px 0;
    background-color: $secondary-black;
    box-shadow: $drop-shadow;
    font-size: 2rem;
    z-index: 1000;

    a {
        color: $link;
    }
}