// fbb97c-a40e4c-2a2d34-1a1423-2d0854
$primary: #FBB97C;
$secondary: #2D0854;
$secondary-dark: #1a1423;
$accent: #a40e4c;

$link: #ddddff;

$black: #1c1c1c;
$secondary-black: #131313;
$white: #fcfcfc;
$gray: #2a2d34;

$drop-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.5);